<template>
	<div>
		<div class="page-title">
			<span style="font-size: 20px;">分销商列表</span>
		</div>
		<div class="bg-white p-2 m-2">
			<div class="d-flex align-items-center">
				<div class="d-flex align-items-center m-1">
					<el-input style="width: 200px" size="small" v-model="search.keyword" placeholder="请输入搜索内容" clearable
						@clear="toSearch" @keyup.enter.native="toSearch">
					</el-input>
					<el-button class="ml-1" @click="toSearch" type="primary" size="mini" icon="el-icon-search">
					</el-button>
					<router-link :to="{name:'member.dealer.user/add'}">
						<el-button class="ml-2" size="mini">
							添加
						</el-button>
					</router-link>
				</div>
			</div>
			<el-table border class="mt-3" :data="lists.data" style="width: 100%">
				<el-table-column width='220' prop="user_id" label="用户">
					<template slot-scope="scope">
						<div class="d-flex m-0 p-0">
							<viewer>
								<img style="width: 45px;height: 45px;" :src="scope.row.avatarUrl">
							</viewer>
							<div class="m-0 p-0 ml-2">
								<div flex="dir:left cross:center">昵称：{{scope.row.nickName}}</div>
								<div flex="dir:left cross:center">ID：（{{scope.row.user_id}}）</div>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column width="120" align="center" label="姓名/手机号">
					<template slot-scope="scope">
						<p class="m-0 p-0">{{scope.row.real_name}}</p>
						<p class="m-0 p-0">{{scope.row.mobile}}</p>
					</template>
				</el-table-column>
				<el-table-column align="center" label="分销佣金">
					<template slot-scope="scope">
						<p class="m-0 p-0">累计佣金：{{scope.row.money * 1 + scope.row.total_money * 1 + scope.row.freeze_money * 1}}</p>
						<p class="m-0 p-0">可提现佣金：{{scope.row.money}}</p>
					</template>
				</el-table-column>
				<el-table-column align="center" width="140" label="推荐人">
					<template slot-scope="scope">
						<template v-if="scope.row.referee_id">
							<p class="m-0 p-0">{{scope.row.referee.nickName}}</p>
							<p class="m-0 p-0">(ID：{{scope.row.referee.user_id}})</p>
						</template>
						<template v-else>
							<p>平台</p>
						</template>
					</template>
				</el-table-column>
				<el-table-column align="center" width="120" label="下级用户">
					<template slot-scope="scope">
						<p class="m-0 p-0">一级：{{scope.row.first_num}}</p>
						<p class="m-0 p-0">二级：{{scope.row.second_num}}</p>
						<p class="m-0 p-0">三级：{{scope.row.third_num}}</p>
					</template>
				</el-table-column>
				<el-table-column prop="create_time" label="添加时间"></el-table-column>
				<el-table-column label="操作" width="300">
					<template slot-scope="scope">
						<!-- <el-button class="set-el-button" size="mini" type="text" circle
							@click="handleEdit(scope.$index, scope.row,list.user_id)">
							<el-tooltip class="item" effect="dark" content="编辑" placement="top">
								<img src="/statics/img/store/edit.png" alt="">
							</el-tooltip>
						</el-button>
						<el-button class="set-el-button" size="mini" type="text" circle
							@click="handleOrder(scope.$index, scope.row,list.user_id)">
							<el-tooltip class="item" effect="dark" content="分销订单" placement="top">
								<img src="/statics/img/store/order.png" alt="">
							</el-tooltip>
						</el-button>
						<el-button class="set-el-button" size="mini" type="text" circle
							@click="handleOperationOrder(scope.$index, scope.row,list.user_id)">
							<el-tooltip class="item" effect="dark" content="运营地区订单" placement="top">
								<img src="/statics/img/store/order.png" alt="">
							</el-tooltip>
						</el-button>
						<el-button class="set-el-button" size="mini" type="text" circle
							@click="handleWithdraw(scope.$index, scope.row,list.user_id)">
							<el-tooltip class="item" effect="dark" content="查看提现记录" placement="top">
								<img src="/statics/img/store/withdraw.png" alt="">
							</el-tooltip>
						</el-button>
						<el-button class="set-el-button" size="mini" type="text" circle
							@click="handleFans(scope.$index, scope.row,list.user_id)">
							<el-tooltip class="item" effect="dark" content="下线" placement="top">
								<img src="/statics/img/store/fans.png" alt="">
							</el-tooltip>
						</el-button> -->
					</template>
				</el-table-column>
			</el-table>
			<div style="height: 30px;" class="mt-2 px-2">
				<el-pagination :page-size="lists.per_page" :current-page.sync="search.page"
					style="display: inline-block;float: right;" background @current-change="pageChange"
					layout="prev, pager, next" :total="lists.total">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		inject: ['layout'],
		data() {
			return {
				lists: [],
				search: {
					s: 'store/member.dealer.user/index',
					keyword: '',
					page: 1,
				},
			}
		},
		created() {
			this.getList();
		},
		methods: {
			toSearch() {
				this.search.page = 1;
				this.getList()
			},
			// 分页
			pageChange(page) {
				this.search.page = page;
				this.getList();
			},
			getList() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: this.search,
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.lists = res.data.data
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
		},
	}
</script>
<style>
</style>
